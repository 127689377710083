<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-reseller" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Create a Reseller Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label>Login Username</label>
                 <input
                    v-model.trim="formData.username"
                    @input="formData.username = $event.target.value.toLowerCase()"
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    name="username"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.username.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.username.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.username.required"
                       >Username is required.</span
                       >
                    <span v-else-if="!$v.formData.username.validUsername"
                       >Username must contain only lowercase alphabets and numbers.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Reseller Name</label>
                 <input
                    v-model="formData.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              
              <div class="mb-3 col-12 col-lg-6">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.formData.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.formData.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.formData.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.formData.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.formData.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model="formData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"
                    />
              </div>
              <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-14 text-warning me-2"></i> Password is Case Sensitive. </p>
              <hr>
              <div class="mb-3">
                <label>Reseller Upline</label>
                <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getData()">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                            {{ props.option.account_name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                            
                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} 
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="formData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> No Contract Available</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
                <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                  <thead class="custom_top_data_bar text-uppercase">
                    <tr>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                    <tr>
                      <td>
                      </td>
                      <td class="align-top text-wrap">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                    </tr>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3 d-none" variant="info" ref="dropdown" v-if="formData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <div class="form-check form-switch form-check-custom ms-auto font-size-15 text-nowrap d-none" >
                      <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                      <input class="form-check-input" type="checkbox" :id="index" v-model="formData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                      <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                    </div>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-4">
                    <table class="table table-bordered align-middle custom-table mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charges</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payin_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payin_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payout_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payout_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].settlement_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].settlement_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].ewallet_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].ewallet_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="updateData.account_username!==defaultName"><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Upline Earning Rate and Extra Charges ({{ value.currency.$model|| '-' }})</td></tr>                      
                        <tr v-if="updateData.account_username!==defaultName">
                          <td>Reseller 
                            <input type="text" class="form-control" v-model="formData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="addResellerRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-reseller-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit Reseller Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Login Username</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_username || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Reseller Code</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.reseller_code || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Account Status</label>
                  <div>
                    <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">

                      <div v-if="uplineInfo.length"> 
                        <input class="form-check-input" type="checkbox" role="switch" :disabled="loading || (uplineInfo[uplineInfo.length - 1].status_flag==0)" v-if="uplineInfo[uplineInfo.length - 1].status_flag==0">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="loading" v-else>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Login Block
                    <span v-if="formEditData.login_block==1" class="ms-1 badge bg-soft-danger rounded-pill font-size-12">Blocked</span>
                  </label>
                  <div>
                    <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.login_block" true-value="1" false-value="0" :disabled="loading">
                 

                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Reseller Name</label>
                 <input
                    v-model="formEditData.name"
                    type="text"
                    class="form-control"
                    placeholder="Reseller Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model="formEditData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"
                    />
              </div>
              <div class="mb-4 col-12 col-lg-12">
              <label>Password</label>
                  <div> <button class="btn btn-info py-2 font-size-15 rounded" @click="showResetPasswordModal()">
                      <i class="uil uil-lock-alt"></i> Reset Password</button></div>
              </div>
              <hr>
             
              <div v-if="updateData.account_username!==defaultName" class="mb-3">
                <label>Reseller Upline</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> -</div>
              </div>
              
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr>
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3 d-none" variant="info" ref="dropdown2" v-if="formEditData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formEditData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formEditData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
               
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formEditData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <!-- <span class="text-danger">upline_disable: [{{ uplineCost[index].upline_disable }}]</span> -->
                      <div class="form-check form-switch form-check-custom ms-auto font-size-15 text-nowrap d-none" >
                        <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                        <input class="form-check-input" type="checkbox" :id="index" v-model="formEditData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                        <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formEditData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                      </div>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-4">
                    <table class="table table-bordered align-middle custom-table mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charges</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payin_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payin_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payout_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payout_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].settlement_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].settlement_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].ewallet_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].ewallet_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="updateData.account_username!==defaultName"><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Upline Earning Rate and Extra Charges ({{ value.currency.$model || '-' }})</td></tr>                      
                        <tr v-if="updateData.account_username!==defaultName">
                          <td>Reseller 
                            <input type="text" class="form-control" v-model="formEditData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13"> {{ updateData.account_name }} -  Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                                + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller-edit'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateResellerRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-reseller-contract" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-usd-circle me-1 text-primary"></i> Reseller's Contract
           </h5>
        </template>
        <div class="row align-items-center">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Reseller Username</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_username }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Reseller Name</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Reseller Code</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" >{{ updateData.reseller_code }}</span>
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Remarks</label>
                 <div>
                  <span class="text-muted font-size-14">{{ formEditData.remarks || '-'}} </span>
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-12">
                 <label class="mb-1">Code for Support</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.code_for_support_skype || '-'}}</span></div>
              </div>
              <hr>
              <div v-if="updateData.account_username!==defaultName && account_type=='admin'" class="mb-3">
                <label>Reseller Upline</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="ownCost.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in ownCost" :key="index">
                    <span v-if="value.status==1">{{value.contract_name}}</span>
                  </span>
                   <div v-if="!ownCost.some(e => e.status==1)"> -</div>
                </div>
                <div v-else>-</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="updateData.current_account_db_code!==updateData.account_db_code">
              <div class="mb-3" v-for="(value, index) in contracts" :key="index">
                  <div v-if="value.status==1">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency|| '-' }})</h4>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-3">
                    <table class="table border align-middle mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Contract <br>Currency</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                            {{parseFloat(uplineCost[index].payin_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payin_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payout_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payout_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                              {{parseFloat(uplineCost[index].settlement_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].settlement_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                              {{parseFloat(uplineCost[index].ewallet_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].ewallet_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="updateData.account_username!==defaultName"><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Upline Earning Rate and Extra Charges ({{ value.currency || '-' }})</td></tr>                      
                        <tr v-if="updateData.account_username!==defaultName" class="bg-soft-success">
                          <td>{{formEditData.contract_selected[index].currency}}
                          </td>
                          <td class="text-wrap text-start">
                            <div class="row">
                              <div class="col-6">
                              {{value.payin_rate.toFixed(2)}}%
                              </div>
                              <div class="col-6">
                              +  {{value.payin_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="text-wrap text-start">
                            <div class="row">
                              <div class="col-6">
                              {{value.payout_rate.toFixed(2)}}%
                              </div>
                              <div class="col-6">
                              + {{value.payout_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="text-wrap text-start">
                            <div class="row">
                              <div class="col-6">
                                {{value.settlement_rate.toFixed(2)}}%
                              </div>
                              <div class="col-6">
                              + {{value.settlement_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="text-wrap text-start">
                            <div class="row">
                              <div class="col-6">
                                {{value.ewallet_rate.toFixed(2)}}%
                              </div>
                              <div class="col-6">
                                + {{value.ewallet_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">{{ updateData.account_name }} -  Rate and Extra Charges  ({{ value.currency || '-' }})</td>
                        </tr>
                        <tr>
                          <td>{{ value.currency || '-' }} </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                                {{ ownCost[index].payin_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              + {{ ownCost[index].payin_extra_charge.toFixed(2) }}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                                {{ ownCost[index].payout_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              + {{ ownCost[index].payout_extra_charge.toFixed(2) }}
                              
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                                {{ ownCost[index].settlement_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              +  {{ ownCost[index].settlement_extra_charge.toFixed(2) }}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-start">
                              <div class="col">
                                {{ ownCost[index].ewallet_rate.toFixed(2) }}%
                                
                              </div>
                              <div class="col">
                              + {{ ownCost[index].ewallet_extra_charge.toFixed(2) }}
                              
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="mb-3" v-for="(value, index) in ownCost" :key="index">
                  <div v-if="value.status==1">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.contract_currency|| '-' }})</h4>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-3">
                    <table class="table table-bordered align-middle mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Contract <br>Currency</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Current Reseller Rate and Extra Charges  ({{ value.contract_currency || '-' }})</td>
                        </tr>
                        <tr>
                          <td>{{ value.contract_currency || '-' }} </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ ownCost[index].payin_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              + {{ ownCost[index].payin_extra_charge.toFixed(2) }}
                            </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ ownCost[index].payout_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              + {{ ownCost[index].payout_extra_charge.toFixed(2) }}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ ownCost[index].settlement_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              +  {{ ownCost[index].settlement_extra_charge.toFixed(2) }}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ ownCost[index].ewallet_rate.toFixed(2) }}%
                              </div>
                              <div class="col">
                              + {{ ownCost[index].ewallet_extra_charge.toFixed(2) }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller-contract'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-reseller-wallet" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-wallet me-1 text-primary"></i> Reseller's Wallet
           </h5>
        </template>
        <div class="row align-items-center">
              <div class="mb-3">
                 <label class="mb-1">Reseller's Name</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                  <span class="badge bg-soft-primary font-size-14" v-else>David</span>
                </div>
              </div>
              <hr>
              <div v-if="updateData.account_username!==defaultName && account_type=='admin'" class="mb-3">
                <label>Reseller Upline</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-1" v-if="!loading">
                <div>
                <div class="d-flex text-lg-end">
                    <h4 class="font-size-14">Wallet</h4>
                </div>

                <div class="table-responsive table-hover text-nowrap font-size-14">
                  <table class="table align-middle">
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Currency</th>
                        <th class="text-end">Payin <span class="badge bg-secondary ms-1 lh-sm">Comm</span></th>
                        <th class="text-end">E-Wallet <span class="badge bg-secondary ms-1 lh-sm">Comm</span></th>
                        <th class="text-end">Payout <span class="badge bg-secondary ms-1 lh-sm">Comm</span></th>
                        <th class="text-end">Settlement <span class="badge bg-secondary ms-1 lh-sm">Comm</span></th>
                        <th class="text-end">Withdrawal</th>
                        <th class="text-end">Contract Balance</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr v-for="(value, index) in wallets" :key="index">
                        <td>{{value.currency}}</td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payin, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_ewallet, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payout, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_settlement, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_withdrawal || 0, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.contract_balance, true)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller-wallet'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-reseller-special-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit Reseller Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Login Username</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_username || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Reseller Code</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.reseller_code || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Reseller Name</label>
                 <input
                    v-model="formEditData.name"
                    type="text"
                    class="form-control"
                    placeholder="Reseller Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model="formEditData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"
                    />
              </div>
              <hr>
             
              <div v-if="updateData.account_username!==defaultName" class="mb-3">
                <label>Reseller Upline</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> -</div>
              </div>
              
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr>
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in formEditData.contract_selected" :key="index">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency || '-' }})</h4>

                      <div v-if="formEditData.contract_selected">
                       <!-- <div class="form-check form-switch form-check-custom ms-auto font-size-15 text-nowrap" >
                      <input class="form-check-input" type="checkbox" :id="index" v-model="formEditData.contract_selected[index].status" true-value="1" false-value="0">
                      <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formEditData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                    </div>-->
                      </div>
                  </div>
                  <div>uplineCost</div>
                  <pre>{{ uplineCost[index] }}</pre>
                  <div>contract</div>
                  <pre>{{value }}</pre>
                  <div class="table-responsive text-nowrap font-size-14 mb-4">
                    <table class="table table-bordered align-middle custom-table mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charges</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-center">
                              
                              <div class="col">
                              <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].payin_rate.$error || (value.payin_rate || 0)<0}" 
                                  v-model="uplineCost[index].payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','payin_rate',index);
                                  value.payin_rate = parseFloat(ownCost[index].payin_rate) - parseFloat(uplineCost[index].payin_rate)
                                  "
                                  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!uplineCost.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].payin_extra_charge.$error }"
                                  v-model="uplineCost[index].payin_extra_charge" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','payin_extra_charge',index);
                                   value.payin_extra_charge = parseFloat(ownCost[index].payin_extra_charge) - parseFloat(uplineCost[index].payin_extra_charge || 0)
                                  " 
                                  
                                  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].payin_extra_charge.required"
                                    >The extra charge is required.</span
                                    >
                                  <span v-else-if="!uplineCost.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].payout_rate.$error || (value.payout_rate || 0)<0}" 
                                  v-model="uplineCost[index].payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','payout_rate',index);
                                  value.payout_rate = parseFloat(ownCost[index].payout_rate) - parseFloat(uplineCost[index].payout_rate)
                                  "
                                  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!uplineCost.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].payout_extra_charge.$error || (value.payout_extra_charge || 0)<0 }"
                                  v-model="uplineCost[index].payout_extra_charge" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','payout_extra_charge',index);
                                   value.payout_extra_charge = parseFloat(ownCost[index].payout_extra_charge) - parseFloat(uplineCost[index].payout_extra_charge || 0)
                                  "  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].payout_extra_charge.required"
                                    >The extra charge is required.</span
                                    >
                                  <span v-else-if="!uplineCost.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].settlement_rate.$error || (value.settlement_rate || 0)<0}" 
                                  v-model="uplineCost[index].settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','settlement_rate',index);
                                  value.settlement_rate = parseFloat(ownCost[index].settlement_rate) - parseFloat(uplineCost[index].settlement_rate)
                                  "
                                  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!uplineCost.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].settlement_extra_charge.$error || (value.settlement_extra_charge || 0)<0 }"
                                  v-model="uplineCost[index].settlement_extra_charge" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','settlement_extra_charge',index);
                                   value.settlement_extra_charge = parseFloat(ownCost[index].settlement_extra_charge) - parseFloat(uplineCost[index].settlement_extra_charge || 0)
                                  " >
                                <div
                                  v-if="modalSubmit && uplineCost[index].settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].settlement_extra_charge.required"
                                    >The extra charge is required.</span
                                    >
                                  <span v-else-if="!uplineCost.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].ewallet_rate.$error || (value.ewallet_rate || 0)<0}" 
                                  v-model="uplineCost[index].ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','ewallet_rate',index);
                                  value.ewallet_rate = parseFloat(ownCost[index].ewallet_rate) - parseFloat(uplineCost[index].ewallet_rate)
                                  "
                                  >
                                <div
                                  v-if="modalSubmit && uplineCost[index].ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!uplineCost.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && uplineCost[index].ewallet_extra_charge.$error || (value.ewallet_extra_charge || 0)<0 }"
                                  v-model="uplineCost[index].ewallet_extra_charge" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV4($event, 'uplineCost','ewallet_extra_charge',index);
                                   value.ewallet_extra_charge = parseFloat(ownCost[index].ewallet_extra_charge) - parseFloat(uplineCost[index].ewallet_extra_charge || 0)
                                  " >
                                <div
                                  v-if="modalSubmit && uplineCost[index].ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!uplineCost[index].ewallet_extra_charge.required"
                                    >The extra charge is required.</span
                                    >
                                  <span v-else-if="!uplineCost.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="updateData.account_username!==defaultName"><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Upline Earning Rate and Extra Charges ({{ value.currency || '-' }})</td></tr>                      
                        <tr v-if="updateData.account_username!==defaultName">
                          <td>Reseller 
                            <input type="text" class="form-control" v-model="formEditData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-middle text-wrap">
                            <div class="row text-center">
                              <div class="col-6">
                                <span :class="{'text-danger':(value.payin_rate || 0)<0}"> 
                                  {{ parseFloat(value.payin_rate || 0).toFixed(2) }}%
                                </span>
                              </div>
                              <div class="col-6">
                                + 
                                <span :class="{'text-danger':(value.payin_extra_charge || 0)<0}"> 
                                  {{ parseFloat(value.payin_extra_charge || 0).toFixed(2) }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="align-middle text-wrap">
                            <div class="row text-center">
                              <div class="col-6">
                                <span :class="{'text-danger':(value.payout_rate || 0)<0}"> 
                                  {{ parseFloat(value.payout_rate || 0).toFixed(2) }}%
                                </span>
                              </div>
                              <div class="col-6">
                                + 
                                <span :class="{'text-danger':(value.payout_extra_charge || 0)<0}"> 
                                  {{ parseFloat(value.payout_extra_charge || 0).toFixed(2) }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row text-center">
                              <div class="col-6">
                                <span :class="{'text-danger':(value.settlement_rate || 0)<0}"> 
                                  {{ parseFloat(value.settlement_rate || 0).toFixed(2) }}%
                                </span>
                              </div>
                              <div class="col-6">
                                + 
                                <span :class="{'text-danger':(value.settlement_extra_charge || 0)<0}"> 
                                  {{ parseFloat(value.settlement_extra_charge || 0).toFixed(2) }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row text-center">
                              <div class="col-6">
                                <span :class="{'text-danger':(value.ewallet_rate || 0)<0}"> 
                                  {{ parseFloat(value.ewallet_rate || 0).toFixed(2) }}%
                                </span>
                              </div>
                              <div class="col-6">
                                + 
                                <span :class="{'text-danger':(value.ewallet_extra_charge || 0)<0}"> 
                                  {{ parseFloat(value.ewallet_extra_charge || 0).toFixed(2) }}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13"> {{ updateData.account_name }} -  Rate and Extra Charges  ({{ value.currency || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(ownCost[index].payin_rate || 0).toFixed(2)}}%
                              </div>
                              <div class="col">
                                +
                                {{ parseFloat(ownCost[index].payin_extra_charge || 0).toFixed(2)}}
                               
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{parseFloat(ownCost[index].payout_rate || 0).toFixed(2)}}%
                              </div>
                              <div class="col">
                                +
                                {{ parseFloat(ownCost[index].payout_extra_charge || 0).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{parseFloat(ownCost[index].settlement_rate || 0).toFixed(2)}}%
                              </div>
                              <div class="col">
                                +
                                {{ parseFloat(ownCost[index].settlement_extra_charge || 0).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{parseFloat(ownCost[index].ewallet_rate || 0).toFixed(2)}}%
                              </div>
                              <div class="col">
                                +
                                {{ parseFloat(ownCost[index].ewallet_extra_charge || 0).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller-special-edit'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateSpecialResellerRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal2" ref="modal-reset-password" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Reset Password
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-lg-12">
              <label>Username </label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_username || '-' }}</span></div>
          
          </div>
          <div class="mb-3 col-12 col-lg-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.resetPassword.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.resetPassword.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.resetPassword.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.resetPassword.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.resetPassword.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reset-password'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="resetPasswordRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Reseting...</span>
                 <span v-else> Reset Password </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-reseller-update-balance" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-wallet me-1 text-primary"></i> Update Balance
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Reseller</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_name || '-' }}</span></div>
          </div>
          
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Reseller Code</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.reseller_code || '-'}}</span></div>
          </div>

          <div class="mb-3 col-12 col-lg-4">
             
          </div>
         
          <div class="mb-1 col-12 col-lg-4">
              <label class="d-block">Adjust by Amount (+/-)</label>
              <div>
                <input type="radio" v-model="updateBalance.type" value="+" class="btn-check" name="adjust_type" id="adding" autocomplete="off" :disabled="modalLoading || loading" >
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateBalance.type=='+'}" for="adding">Addition (+)</label>

                <input type="radio" v-model="updateBalance.type" value="-"  class="btn-check" name="adjust_type" id="subtracting" autocomplete="off" :disabled="modalLoading || loading">
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateBalance.type=='-'}" for="subtracting">Subtraction (-)</label>
              </div>
              <div
                v-if="modalSubmit && $v.updateBalance.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateBalance.type.required"
                    > Type is required.</span
                    >
              </div>
           </div>

          <div class="mb-3 col-12 col-lg-8">
              <label>Amount</label>
              <input
                v-model="updateBalance.amount"
                autocomplete="off"
                type="text"
                inputmode="decimal"
                @input="inputNumberOnlyV2($event, 'updateBalance','amount'), detectOperator($event)" 
                class="form-control"
                placeholder="Amount"
                :disabled="modalLoading || loading"
                name="amount"
                :class="{
                'is-invalid':
                modalSubmit && $v.updateBalance.amount.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.updateBalance.amount.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateBalance.amount.required"
                    >Amount is required.</span
                    >
              </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-3 bg-light rounded">
              <div class="row">
              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
              <label class="mb-0 fw-normal">Current Balance  </label>
              <div>
              <span class="fw-semibold font-size-20" v-if="wallets.length"> 
                {{ convertCurrencyFormat(wallets[0].contract_balance,true)}}
              </span>
              <span v-else class="fw-semibold font-size-20">0.00</span>
              </div>
              </div>

              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Amount</label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="updateBalance.amount">
                    <span v-if="updateBalance.type=='+'" class="text-success">
                      {{ updateBalance.type }} {{ convertCurrencyFormat(updateBalance.amount,true)}}
                    </span> 
                    <span v-if="updateBalance.type=='-'" class="text-danger">
                      {{ updateBalance.type }} {{ convertCurrencyFormat(updateBalance.amount,true)}}
                    </span> 
                  
                  </span>
                  <span v-else class="fw-semibold font-size-20">
                    {{updateBalance.type}} 0.00</span>
                  </div>
              </div>
              <div class="mb-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Est. After Balance  </label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="wallets.length && updateBalance.amount"> 
                    <span v-if="updateBalance.type=='+'">
                    {{ convertCurrencyFormat(wallets[0].contract_balance + parseFloat(updateBalance.amount),true) }}
                    </span>
                    <span v-if="updateBalance.type=='-'">
                    {{ convertCurrencyFormat(wallets[0].contract_balance - parseFloat(updateBalance.amount),true) }}
                    </span>
                  </span>
                  <span v-else class="fw-semibold font-size-20">0.00</span>
                  </div>
              </div>
            </div>
            </div>
          </div>

         

          <div class="mb-3 col-12 col-lg-6">
            <label>Password</label>
            <div class="position-relative">
              <input
                  v-model="updateBalance.password"
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.updateBalance.password.$error,
                  }"
                  placeholder="Password"
                  />
              <div
                  v-if="modalSubmit && $v.updateBalance.password.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.updateBalance.password.required"
                    >Password is required.</span
                    >
              </div>
              <div
                  id="eye"
                  class="user-select-none"
                  @click="switchVisibility()"
                  >
                  <i
                    :class="classicon"
                    id="eyetype"
                    aria-hidden="true"
                    ></i>
              </div>
            </div>
          </div>
          <div class="mb-3 col-12 col-lg-6">
              <label>Remarks</label>
              <input
                v-model="updateBalance.remarks"
                type="text"
                class="form-control"
                placeholder="Remarks"
                name="Remarks"
                />
          </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reseller-update-balance'].hide()">Cancel</button>
               
                <div class="d-inline" v-if="updateBalance.type=='-' && wallets.length">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(wallets[0].contract_balance ) - parseFloat(updateBalance.amount))>=0" @click="updateWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </div>  
                <div class="d-inline" v-if="updateBalance.type=='+' && wallets.length">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(wallets[0].contract_balance ) + parseFloat(updateBalance.amount))>=0" @click="updateWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </div>
              </div>
           </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { required, minLength, sameAs, helpers, between } from "vuelidate/lib/validators";
  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };
  const CancelToken = axios.CancelToken;
  let cancel;
  
  const ResellerDefault =
  {
    "account_db_code": "-1",
    "account_name": "Current Account",
    "account_type": "",
    "up_reseller_count": 0,
    "reseller_parent": [],
    "reseller_level": "",
    "reseller_code": "",
    "merchant_count": 0
  }
  /**
   * Starter page
   */
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        defaultName: appConfig.defaultAccountName,
        updateData: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        returnData: [],
        tabs_index:0,
        uplineCost:[],
        contracts:[],
        ownCost:[],
        wallets:[],
        uplineInfo:[],
        quickUpdate:{
          all_value:"",
          payin_rate: "",
          payin_extra_charge: "",
          payout_rate: "",
          payout_extra_charge: "",
          settlement_rate: "",
          settlement_extra_charge: "",
          ewallet_rate: "",
          ewallet_extra_charge: "",
        },
        formData: {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          remarks:"",
          contract_selected:[],
        },
        formEditData: {
          id:"",
          name:"",
          reseller_code:"",
          code_for_support_skype:"",
          remarks:"",
          contract_selected:[],
          status:"",
          status_flag:"",
          login_block:""
        },
        updateBalance: {
          id:"",
          amount:"",
          type:"+",
          remarks:"",
          password:""
        },
        resetPassword: {
          id:"",
          password: "",
          confirmPassword: ""
        },
        selectedReseller: {
          "account_db_code": "-1"
        },
        reseller:[],
        copyMessages:""
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        // websiteUrl: {
        //   required,
        //   url
        // },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: between(0.0,50)
            },
            payin_extra_charge: {
              required,
              between: between(0,100000)
            },
            payout_rate: {
              required,
              between: between(0.0,50)
            },
            payout_extra_charge: {
              required,
              between: between(0,100000)
            },
            settlement_rate: {
              required,
              between: between(0.0,50)
            },
            settlement_extra_charge: {
              required,
              between: between(0,100000)
            },
            ewallet_rate: {
              required,
              between: between(0.0,50)
            },
            ewallet_extra_charge: {
              required,
              between: between(0,100000)
            }
         }
        },
       
      },
      formEditData: {
        name: {
          required,
        },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: between(0.0,50),
            },
            payin_extra_charge: {
              required,
              between: between(0,100000)
            },
            payout_rate: {
              required,
              between: between(0.0,50)
            },
            payout_extra_charge: {
              required,
              between: between(0,100000)
            },
            settlement_rate: {
              required,
              between: between(0.0,50)
            },
            settlement_extra_charge: {
              required,
              between: between(0,100000)
            },
            ewallet_rate: {
              required,
              between: between(0.0,50)
            },
            ewallet_extra_charge: {
              required,
              between: between(0,100000)
            },
            
         }
        }
      },
      resetPassword: {
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      },
      uplineCost: {
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
            },
            payin_extra_charge: {
              required,
              between: between(0,10000)
            },
            payout_rate: {
              required,
              between: between(0.0,50)
            },
            payout_extra_charge: {
              required,
              between: between(0,10000)
            },
            settlement_rate: {
              required,
              between: between(0.0,50)
            },
            settlement_extra_charge: {
              required,
              between: between(0,10000)
            },
            ewallet_rate: {
              required,
              between: between(0.0,50)
            },
            ewallet_extra_charge: {
              required,
              between: between(0,10000)
            },
            
          }
      },
      updateBalance: {
        id: {
          required,
        },
        amount: {
          required,
        },
        type: {
          required,
        },
        password: {
          required
        }
      }

    },
    middleware: "authentication",
    async mounted() {
      this.reload() 
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        console.log('Dropdown is about to be hide', bvEvent)
        //after dismiss
        this.quickUpdate={
            all_value:"",
            payin_rate: "",
            payin_extra_charge: "",
            payout_rate: "",
            payout_extra_charge: "",
            settlement_rate: "",
            settlement_extra_charge: "",
            ewallet_rate: "",
            ewallet_extra_charge: "",
        }
      })
      this.reload();
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
    },
    created() {
    },
    methods: {
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getResellerDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
              //clear data
              this.wallets=[];
              this.ownCost=[];
              this.contracts=[];
              this.uplineCost=[];
              this.formData.contract_selected=[]
              //
             var returnData = resData.data;
             var upline_contracts= []
             var uplineDisable = false
             returnData.own_cost_list.forEach(element => {
              uplineDisable = element.upline_disable || false
              if (element.initial_contact_enable){
                upline_contracts.push(
                  {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,
                    upline_disable: uplineDisable
                  },
                )
                //follow parent upline contract and push fake
                this.formData.contract_selected.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: "0",
                    payin_extra_charge: "0",
                    payout_rate: "0",
                    payout_extra_charge: "0",
                    settlement_rate: "0",
                    settlement_extra_charge: "0",
                    ewallet_rate: "0",
                    ewallet_extra_charge: "0",
                    status: 1
                  }
                );
              }
             });
             this.uplineCost = upline_contracts;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getEditData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.data.updateData.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getResellerDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
             var returnData = resData.data;
             var upline_contracts= []
             var contracts = []
             var wallets = []
             var downlineTotalCost = []
             var uplineDisable = false
             returnData.upline_contract.forEach(element => {
              uplineDisable = element.upline_disable || false
              upline_contracts.push(
                {
                  contract_name: element.contract_name,
                  contract_currency: element.contract_currency,
                  payin_rate: element.payin_rate,
                  payin_extra_charge: element.payin_extra_charges,
                  payout_rate: element.payout_rate,
                  payout_extra_charge: element.payout_extra_charges,
                  settlement_rate: element.settlement_rate,
                  settlement_extra_charge: element.settlement_extra_charges,
                  ewallet_rate: element.ewallet_rate,
                  ewallet_extra_charge:element.ewallet_extra_charges,
                  upline_disable:uplineDisable,
                  status: (element.contract_status  === 'available') ? 1 : 0,
                }
              )
             });
             returnData.contract.forEach(element => {
              contracts.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,
                    
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });

             returnData.own_cost_list.forEach(element => {
              downlineTotalCost.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,

                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });
             
             
             returnData.wallets.forEach(element => {
              wallets.push(
                {
                  currency: element.contract_currency,
                  total_payin: element.total_payin,
                  total_ewallet: element.total_ewallet,
                  total_payout: element.total_payout,
                  total_settlement: element.total_settlement,
                  total_withdrawal: element.total_withdrawal || 0,
                  contract_total:element.contract_total,
                  contract_balance: element.contract_balance,
                },
              )
             });
             
             
             var dataReturn ={
                name: returnData.account_name,
                username: returnData.account_username,
                remarks: returnData.remarks|| '',
                status: returnData.status,
                status_flag: returnData.status_flag,
                login_block: returnData.login_block,
                reseller_code: returnData.reseller_code,
                code_for_support_skype: returnData.code_for_support_skype || returnData.reseller_code,
                upline_contract: upline_contracts,
                contract:contracts,
                wallets: wallets,
                reseller_parent: returnData.reseller_parent,
                downlineTotalCost: downlineTotalCost
              }
             
              this.formEditData.name = dataReturn.name
              this.formEditData.remarks = dataReturn.remarks
              this.formEditData.reseller_code = dataReturn.reseller_code
              this.formEditData.code_for_support_skype = dataReturn.code_for_support_skype
              this.formEditData.status = (dataReturn.status  === 'active') ? 1 : 0
              this.formEditData.status_flag = dataReturn.status_flag
              this.formEditData.login_block = dataReturn.login_block

              this.uplineCost= dataReturn.upline_contract
              this.uplineInfo= dataReturn.reseller_parent
              dataReturn.contract.forEach(element => {
                this.formEditData.contract_selected.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance
                  }
                );
                this.contracts.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance
                  }
                );
              });
              this.wallets = dataReturn.wallets
              this.ownCost = downlineTotalCost

              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getReseller() {
      if(cancel !== undefined) cancel();
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(c => cancel = c)
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = []
          this.selectedReseller = ResellerDefault
          resData.data.forEach((element) => {
            this.reseller.push(element);
          });
          this.selectedReseller = this.reseller[0]
          //if login as reseller
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
          }
          this.getData();

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    updateAllInput(){
      if (this.quickUpdate.all_value!==''){
        this.quickUpdate={
            all_value:this.quickUpdate.all_value,
            payin_rate: this.quickUpdate.all_value,
            payin_extra_charge: this.quickUpdate.all_value,
            payout_rate: this.quickUpdate.all_value,
            payout_extra_charge: this.quickUpdate.all_value,
            settlement_rate: this.quickUpdate.all_value,
            settlement_extra_charge: this.quickUpdate.all_value,
            ewallet_rate: this.quickUpdate.all_value,
            ewallet_extra_charge: this.quickUpdate.all_value,
        }
      }
    },
    quickUpdateRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
            if (this.quickUpdate.payin_rate!==''){
              element.payin_rate = this.quickUpdate.payin_rate
            }
            if (this.quickUpdate.payin_extra_charge!==''){
              element.payin_extra_charge = this.quickUpdate.payin_extra_charge
            }
            if (this.quickUpdate.payout_rate!==''){
              element.payout_rate = this.quickUpdate.payout_rate
            }
            if (this.quickUpdate.payout_extra_charge!==''){
              element.payout_extra_charge = this.quickUpdate.payout_extra_charge
            }
            if (this.quickUpdate.settlement_rate!==''){
              element.settlement_rate = this.quickUpdate.settlement_rate
            }
            if (this.quickUpdate.settlement_extra_charge!==''){
              element.settlement_extra_charge = this.quickUpdate.settlement_extra_charge
            }
            if (this.quickUpdate.ewallet_rate!==''){
              element.ewallet_rate = this.quickUpdate.ewallet_rate
            }
            if (this.quickUpdate.ewallet_extra_charge!==''){
              element.ewallet_extra_charge = this.quickUpdate.ewallet_extra_charge
            }
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if  (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
      
    },
    resetRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
          element.payin_rate = 0
          element.payin_extra_charge = 0
          element.payout_rate = 0
          element.payout_extra_charge = 0
          element.settlement_rate = 0
          element.settlement_extra_charge = 0
          element.ewallet_rate = 0
          element.ewallet_extra_charge = 0
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel][childModel] = val;
      // }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel].contract_selected[index][childModel] = val;
      // }
    },
    inputNumberOnlyV4(event, parentModel, childModel, index) {
      console.log(event, parentModel, childModel, index)
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel][index][childModel] = numericValue
      if(numericValue.length > 2){
        var val = numericValue.replace(/[^0-9]/g, '');
        console.log(val);
        val = val.substr(0, val.length-2)+"."+val.substr(-2);
        this[parentModel][index][childModel] = val
      }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    showModal() {
      this.$refs['modal-reseller'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.getReseller()
      //this.getData();
    },
    showEditModal() {
      this.$refs['modal-reseller-edit'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.formEditData.id = this.data.updateData.account_db_code

      this.resetPassword =  {
        id:this.data.updateData.account_db_code,
        password: "",
        confirmPassword: ""
      }
      this.getEditData();
    },
    showSpecialEditModal() {
      this.$refs['modal-reseller-special-edit'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.formEditData.id = this.data.updateData.account_db_code
      this.getEditData();
    },
    showResetPasswordModal() {
      this.$refs['modal-reset-password'].show()
    },
    showResellerUpdateBalanceModal() {
      this.$refs['modal-reseller-update-balance'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.updateBalance.id = this.data.updateData.account_db_code;
      this.updateBalance.type = "+";
      //this.getGroups();
      this.getEditData();
    },
    detectOperator(event) {
      if (event.target.value.includes('+')) {
        this.updateBalance.type = '+'
      } else if (event.target.value.includes('-')) {
        this.updateBalance.type = '-'
      } 
    },
    showViewModal(type) {
      if (type =='contract'){
        this.$refs['modal-reseller-contract'].show()
      }else if (type =='wallet'){
        this.$refs['modal-reseller-wallet'].show()
      }
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.getEditData();
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.wallets=[];
      this.ownCost=[];
      this.contracts=[];
      this.uplineCost=[];
      this.formData={
        username:"",
        password: "",
        confirmPassword: "",
        name:"",
        remarks:"",
        contract_selected:[],
      }
      this.formEditData={
        id: "",
        name:"",
        remarks:"",
        contract_selected:[],
        status:"",
        status_flag:"",
        login_block:""
      }
      this.resetPassword =  {
        id:"",
        password: "",
        confirmPassword: ""
      }
      this.updateBalance= {
        id:"",
        amount:"",
        type:"+",
        remarks:"",
        password:""
      }
    },
    resetModal2() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.resetPassword.password= ""
      this.resetPassword.confirmPassword= ""
    },
    resetPasswordRequest(){
      this.modalSubmit = true
      this.$v.resetPassword.$touch();
      console.log((this.$v.resetPassword.$invalid))

      if (this.$v.resetPassword.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("accountDbCode", this.resetPassword.id);
        bodyFormData.append("password", this.resetPassword.password);
        axios({
            method: "post",
            url:  appConfig.APIHostAdmin + 'controller/admin/resetResellerMerchantPassword',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Password Reset Success',
                html: 'Your password for this account has been successfully reset!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reset-password'].hide()
                this.$refs['modal-reseller-edit'].hide()
                this.$emit('callParentFunction2');
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    addResellerRequest(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      console.log((this.$v.formData.$invalid))
     
      var NoContactEnabled = this.formData.contract_selected.map((item) => parseInt(item.status)).includes(1);

      if (this.$v.formData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      }
       else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData = []
        this.formData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("name", this.formData.name);
        bodyFormData.append("username", this.formData.username);
        bodyFormData.append("password", this.formData.password);
        bodyFormData.append("remarks", this.formData.remarks);
        bodyFormData.append("resellerUplineId", this.selectedReseller.account_db_code);
        bodyFormData.append("contracts", JSON.stringify(contractData));

        axios({
            method: "post",
            //url:  appConfig.DemoAPI,
            url: appConfig.APIHostAdmin + 'controller/admin/addReseller',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.copyMessages = `Welcome to ${appConfig.title}!\nYour reseller account is ready!\n\nURL: https://portal.zpay.world\n\nUsername: ${this.formData.username}\nPassword: ${this.formData.password}`

              // Swal.fire({
              //   icon: 'success',
              //   title: `Welcome to ${appConfig.title}`,
              //   //html: 'Your reseller account has been successfully created!',
              //   html: `
              //   <p>Your Reseller account is ready!</p>
              //   <p>URL: <a href="https://portal.zpay.world" target="_blank">https://portal.zpay.world</a></p>
              //   <p>
              //     Username: ${this.formData.username}<br>
              //     Password: ${this.formData.password}
              //   </p>`,
              //   confirmButtonColor: '#FA6541',
              //   showCancelButton: true,
              //   cancelButtonText: "Cancel",
              //   confirmButtonText: `<i class="bi bi-copy"></i> Copy Message`,
              // }).then((result) => {
              //   console.log(result)
              //   console.log(this.copyMessages)
              //   if (result.isConfirmed) {
              //     Swal.fire("Message Copied!", "You can paste and send to your reseller.", "success");
              //     const msg = this.copyMessages;
              //     navigator.clipboard.writeText(msg)
              //   }
              //   this.copyMessages=""
              //   this.$refs['modal-reseller'].hide()
              //   this.$emit('callParentFunction', this.selectedReseller.account_db_code);
              // })
              Swal.fire({
                icon: 'success',
                title: 'Reseller Account Created',
                html: 'Your reseller account has been successfully created!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reseller'].hide()
                this.$emit('callParentFunction');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateResellerRequest(){
      this.modalSubmit = true
      this.$v.formEditData.$touch();
      console.log((this.$v.formEditData.$invalid))
      var NoContactEnabled = this.formEditData.contract_selected.map((item) => parseInt(item.status)).includes(1);
      if (this.$v.formEditData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData =[]
        this.formEditData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("resellerId", this.formEditData.id);
        bodyFormData.append("name", this.formEditData.name);
        bodyFormData.append("remarks", this.formEditData.remarks);
        bodyFormData.append("status", this.formEditData.status);
        bodyFormData.append("loginBlock", this.formEditData.login_block);
        bodyFormData.append("contracts", JSON.stringify(contractData));
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateReseller',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Reseller Account Updated',
                html: 'Your reseller account has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reseller-edit'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateSpecialResellerRequest(){
      this.modalSubmit = true
      this.$v.formEditData.$touch();
      console.log((this.$v.formEditData.$invalid))
      var NoContactEnabled = this.formEditData.contract_selected.map((item) => parseInt(item.status)).includes(1);
      if (this.$v.formEditData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData =[]
        this.formEditData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate.toFixed(2),
            payin_extra_charges: element.payin_extra_charge.toFixed(2),
            payout_rate: element.payout_rate.toFixed(2),
            payout_extra_charges: element.payout_extra_charge.toFixed(2),
            settlement_rate:element.settlement_rate.toFixed(2),
            settlement_extra_charges: element.settlement_extra_charge.toFixed(2),
            ewallet_rate: element.ewallet_rate.toFixed(2),
            ewallet_extra_charges: element.ewallet_extra_charge.toFixed(2),
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("resellerId", this.formEditData.id);
        bodyFormData.append("name", this.formEditData.name);
        bodyFormData.append("remarks", this.formEditData.remarks);
        bodyFormData.append("status", this.formEditData.status);
        bodyFormData.append("loginBlock", this.formEditData.login_block);
        bodyFormData.append("contracts", JSON.stringify(contractData));
        axios({
            method: "post",
            url:  appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Reseller Account Updated',
                html: 'Your reseller account has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reseller-edit'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateWalletBalance(){
      this.modalSubmit = true
      this.$v.updateBalance.$touch();

      if (this.$v.updateBalance.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("resellerId", this.updateBalance.id);
        bodyFormData.append("amount", this.updateBalance.amount);
        bodyFormData.append("type", this.updateBalance.type);
        bodyFormData.append("remarks", this.updateBalance.remarks);
        bodyFormData.append("password", this.updateBalance.password);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateResellerBalance',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              Swal.fire({
                icon: 'success',
                title: 'Balance Updated!',
                html: `${this.updateData.account_name} 's balance has been successfully updated.`,
                confirmButtonColor: '#FA6541',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: `Done`,
              }).then(() => {

                this.$refs['modal-reseller-update-balance'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
    }
  };
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>